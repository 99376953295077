"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modelToHash = exports.hashToModel = void 0;
const Tag_1 = require("@/common/cards/Tag");
const CardType_1 = require("@/common/cards/CardType");
const GameModule_1 = require("@/common/cards/GameModule");
const CardListSearchIndex_1 = require("@/client/components/cardlist/CardListSearchIndex");
const MODULE_ABBREVIATIONS = {
    base: 'b',
    corpera: 'c',
    prelude: 'p',
    prelude2: '2',
    venus: 'v',
    colonies: 'C',
    turmoil: 't',
    community: '*',
    promo: 'r',
    ares: 'a',
    moon: 'm',
    pathfinders: 'P',
    ceo: 'l',
    starwars: 'w',
    underworld: 'u',
};
const TYPE_ABBREVIATIONS = {
    [CardType_1.CardType.EVENT]: 'r',
    [CardType_1.CardType.ACTIVE]: 'b',
    [CardType_1.CardType.AUTOMATED]: 'g',
    [CardType_1.CardType.PRELUDE]: 'p',
    [CardType_1.CardType.CORPORATION]: 'c',
    [CardType_1.CardType.CEO]: 'l',
    [CardType_1.CardType.STANDARD_PROJECT]: 's',
    [CardType_1.CardType.STANDARD_ACTION]: 'S',
    [CardType_1.CardType.PROXY]: '*',
    colonyTiles: 'C',
    globalEvents: 'e',
    milestones: 'm',
    awards: 'a',
};
const TAG_ABBREVIATIONS = {
    [Tag_1.Tag.BUILDING]: '0',
    [Tag_1.Tag.SPACE]: '1',
    [Tag_1.Tag.SCIENCE]: '2',
    [Tag_1.Tag.POWER]: '3',
    [Tag_1.Tag.EARTH]: '4',
    [Tag_1.Tag.JOVIAN]: '5',
    [Tag_1.Tag.VENUS]: '6',
    [Tag_1.Tag.PLANT]: '7',
    [Tag_1.Tag.MICROBE]: '8',
    [Tag_1.Tag.ANIMAL]: '9',
    [Tag_1.Tag.CITY]: 'a',
    [Tag_1.Tag.MOON]: 'b',
    [Tag_1.Tag.MARS]: 'c',
    [Tag_1.Tag.WILD]: 'd',
    [Tag_1.Tag.EVENT]: 'e',
    [Tag_1.Tag.CLONE]: 'f',
    none: 'g',
};
function hashToModel(windowLocationHash) {
    const model = {
        filterText: '',
        namesOnly: true,
        expansions: {
            base: true,
            corpera: true,
            prelude: true,
            prelude2: true,
            venus: true,
            colonies: true,
            turmoil: true,
            community: true,
            ares: true,
            moon: true,
            promo: true,
            pathfinders: true,
            ceo: true,
            starwars: true,
            underworld: true,
        },
        types: {
            event: true,
            active: true,
            automated: true,
            prelude: true,
            corporation: true,
            standard_project: true,
            standard_action: false,
            proxy: false,
            globalEvents: true,
            colonyTiles: true,
            milestones: true,
            awards: true,
            ceo: true,
        },
        tags: {
            building: true,
            space: true,
            science: true,
            power: true,
            earth: true,
            jovian: true,
            venus: true,
            plant: true,
            microbe: true,
            animal: true,
            city: true,
            moon: true,
            mars: true,
            wild: true,
            event: true,
            clone: true,
            none: true,
        },
        searchIndex: new CardListSearchIndex_1.CardListSearchIndex(),
        showAdvanced: false,
    };
    if (windowLocationHash.length > 1) {
        const hash = decodeURIComponent(windowLocationHash).slice(1);
        let remainder = [];
        [model.filterText, ...remainder] = hash.split('~');
        for (const e of remainder) {
            if (e.startsWith('m')) {
                const modules = e.slice(1);
                for (const module of GameModule_1.GAME_MODULES) {
                    const abbrev = MODULE_ABBREVIATIONS[module];
                    model.expansions[module] = modules.includes(abbrev);
                }
            }
            if (e.startsWith('t')) {
                const types = e.slice(1);
                for (const type of Object.keys(model.types)) {
                    const abbrev = TYPE_ABBREVIATIONS[type];
                    model.types[type] = types.includes(abbrev);
                }
            }
            if (e.startsWith('g')) {
                const tags = e.slice(1);
                for (const type of Object.keys(model.tags)) {
                    const abbrev = TAG_ABBREVIATIONS[type];
                    model.tags[type] = tags.includes(abbrev);
                }
            }
        }
    }
    return model;
}
exports.hashToModel = hashToModel;
function encode(vals, abbreviations) {
    const arry = Object.keys(vals);
    const text = arry.filter((e) => vals[e]).map((e) => abbreviations[e]).join('');
    return (text.length !== arry.length) ? text : undefined;
}
function modelToHash(model) {
    const parts = {};
    parts.m = encode(model.expansions, MODULE_ABBREVIATIONS);
    parts.t = encode(model.types, TYPE_ABBREVIATIONS);
    parts.g = encode(model.tags, TAG_ABBREVIATIONS);
    const text = model.filterText +
        (parts.m ? ('~m' + parts.m) : '') +
        (parts.t ? ('~t' + parts.t) : '') +
        (parts.g ? ('~g' + parts.g) : '');
    return '#' + encodeURIComponent(text);
}
exports.modelToHash = modelToHash;
